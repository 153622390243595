import "./horizontal_scroll_style.css";

const list_0 = document.querySelector("#hs_list_0");
const items_0 = Array.from(document.querySelectorAll(
    '#hs_item_00, #hs_item_01, #hs_item_02, #hs_item_03, #hs_item_04, #hs_item_05, #hs_item_06, #hs_item_07,' +
    '#hs_item_08, #hs_item_09, #hs_item_010, #hs_item_011, #hs_item_012, #hs_item_013, #hs_item_014, #hs_item_015'));
const indicators_0 = Array.from(document.querySelectorAll(
    '#hs_indicator_00, #hs_indicator_01, #hs_indicator_02, #hs_indicator_03, #hs_indicator_04, #hs_indicator_05' +
    '#hs_indicator_06, #hs_indicator_07, #hs_indicator_08, #hs_indicator_09, #hs_indicator_010, #hs_indicator_011' +
    '#hs_indicator_012, #hs_indicator_013, #hs_indicator_014, #hs_indicator_015'));
const imgs_0 = Array.from(document.querySelectorAll(
    '#hs_0_img_0, #hs_0_img_1, #hs_0_img_2, #hs_0_img_3, #hs_0_img_4, #hs_0_img_5, #hs_0_img_6, #hs_0_img_7, ' +
    '#hs_0_img_8, #hs_0_img_9, #hs_0_img_10, #hs_0_img_11, #hs_0_img_12, #hs_0_img_13, #hs_0_img_14, #hs_0_img_0, #hs_0_img_15'
));
const prev_0 = document.querySelector("#hs_prev_0");
const next_0 = document.querySelector("#hs_next_0");
const state_0 = { active: 0 };

var imgs_loaded = false;

function checkImgs() {
    var loaded = [];
    imgs_0.forEach((imgs) => {
        loaded.push(imgs.complete && imgs.naturalHeight !== 0);
    })
    if (loaded.every(Boolean)) {
        imgs_loaded = true;
        console.log("!");
    }
}

checkImgs()


function onIntersectionObserved_0(entries) {
    entries.forEach(entry => {
        if (entry.isIntersecting && entry.intersectionRatio >= 0.5) {
            if (imgs_loaded) {
                state_0.active = items_0.indexOf(entry.target);
            } else {
                checkImgs()
                if (imgs_loaded) {
                    state_0.active = items_0.indexOf(entry.target);
                }
            }
            indicators_0.forEach((indicator, i) => {
                indicator.classList.toggle("active", i === state_0.active);
            });
        }
    });
}

const observer_0 = new IntersectionObserver(onIntersectionObserved_0, {
    root: list_0,
    threshold: 0.5
});

items_0.forEach(item => {
    observer_0.observe(item);
});

indicators_0.forEach((x, i) =>
    x.addEventListener("click", () =>
        items_0[i].scrollIntoView({
            block: "nearest",
            // inline: "start",
            behavior: "smooth"
        })
    )
);

prev_0.addEventListener("click", () => {
    items_0[state_0.active - 1]?.scrollIntoView({
        block: "nearest",
        // inline: "start",
        behavior: "smooth"
    });
});

next_0.addEventListener("click", () => {
    items_0[state_0.active + 1]?.scrollIntoView({
        block: "nearest",
        // inline: "start",
        behavior: "smooth"
    });
});
